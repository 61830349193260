import React from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'
import BigButton from '../BigButton'

const Style = styled.div`
  //background: url(${props => props.background}) no-repeat top left;
  //background-size: 25%;
  margin-top: 24px;
  display: grid;
  grid-template-columns: 30% 70%;
  height: 100%;

  .firstColumn {
    a:before {
      content: "";
      background-image: url(${props => props.background});
      background-size: 85px; /* Icon size */
      width: 85px; /* Icon size */
      height: 85px; /* Icon size */
      position: absolute;
      //right: 15px; /* Adjust distance from the right side */
      //top: 50%; /* Vertically center the icon */
    //  transform: translateY(-50%); /* Vertically center the icon */
      margin-top: -20px;
      transition: all .4s ease-in-out;

    }

    &:hover {
      a:before {
        transform: translateY(-0.9375rem);
        background-image: url(${props => props.backgroundHover});
      }
    }
  }


  > div {
    margin: 24px 0;
    padding: 0 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;

    &:first-child a {
      height: 100%;
    }

    h3 {
      font-size: 32px;
      font-weight: 400;
    }

    .content {
        font-size: 16px;
        font-weight: 500;
        border: none;
        color: #ffffff;
        border-radius: 50px;
        padding: 10px 20px 10px 20px;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        margin-top: 24px;
    }
  }


  @media only screen and (max-width: 576px) {
    background: none;
    grid-template-columns: 100%;
    padding: 0;

    > div {
      background: #9ad5cd;
      position: unset;
      width: unset;
      height: unset;
      padding: 24px 24px;

      &:first-child {
        display: none;
      }
    }
  }
`

const HomepageTeaser = ({ title, children, ctas, background, backgroundHover, buttonBackground, state }) => {
  // Use first CTA link as main link
  const link = ctas[0]?.link

  return (
    <Style background={background} backgroundHover={backgroundHover}>
      <div className={"firstColumn"}>
        {link && (
          <Link to={`${link}`} state={state}>&nbsp;</Link>
        )}
      </div>
      <div>
        <div>
          <h3>
            {link && (
              // <Link to={`${link}`} state={state}>{title}</Link>
              <Link to={`${link}`} state={state}>{title}</Link>
            )}
            {!link && title}
          </h3>
          <div>{children}</div>
        </div>

        {ctas.map((cta, index) => (
          <BigButton cta={cta} buttonBackground={buttonBackground}  key={index} state={state}/>
        ))}
        </div>

    </Style>
  )
}

export default HomepageTeaser
