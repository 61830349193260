import React from 'react'
import ContainerWrapper from '../components/ContainerWrapper'
import { Col, Row } from 'react-grid-system'
import styled from 'styled-components'
import Seo from '../components/SEO'
import HomepageTeaser from "../components/HomepageTeaser";
import teaserReferenceBackground from "../images/CoreMedia_Icons_SMS_purple_magenta.svg";
import teaserReferenceBackgroundHover from "../images/CoreMedia_Icons_SMS_red_magenta.svg";
import teaserHowtoGuidesBackground from '../images/CoreMedia_Icons_Blocks_blue_green.svg'
import teaserHowtoGuidesBackgroundHover from '../images/CoreMedia_Icons_Blocks_red_magenta.svg'


const Style = styled.div`
  .log {
    > div {
    }
  }

  p {
    font-size: 18px;
  }

  .image-container {
    background-image: url('../images/CoreMediaLetterbox_blue.jpg');
    height: 400px;
    width: 100%;
    background-size: cover;
    background-position: center;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-color: white;

    > p {
      margin-left: 1rem;
      width: 60%;
    }
  }


h1 {
  font-weight: 500;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  color: white!important;
}
`;

const WhatsNew = ({pageContext, data}) => {

  const {
    breadcrumb: {crumbs},
  } = pageContext

  return (
    <ContainerWrapper crumbs={crumbs} fluid={false}>
      <Seo
        title={`What's New`}
        description={'Highlights of our product releases and access to release webinar recordings and slide decks for community members'}
      />
      <Style>
        <Row>

          <div className="image-container">
            <h1>What's New in<br/> CoreMedia Experience Platform</h1>


          </div>
          <p>
            Discover changelog highlights, release notes, webinars, and more for CoreMedia Content Cloud and
            Engagement
            Cloud updates.
          </p>
        </Row>

        <Row>
          <Col lg={6}>
            <HomepageTeaser
              title={"What's New in Content Cloud"}
              ctas={[{label: 'Content Cloud', link: '/whats-new/whats-new-filter?filter=cmcc'}]}
            background={teaserHowtoGuidesBackground}
            backgroundHover={teaserHowtoGuidesBackgroundHover}
            buttonBackground={"linear-gradient(45deg, #006CAE, #2FAC66)"}
            state={{ filter: 'cmcc' }}
          >
            CoreMedia Content Cloud is the CMS part of the CoreMedia Experience Platform. Find out what's new.
          </HomepageTeaser>
          </Col>
          <Col lg={6}>
            <HomepageTeaser
              title={"What's New in Engagement Cloud"}
              ctas={[
                { label: 'Engagement Cloud', link: '/whats-new/whats-new-filter?filter=cmec'},
              ]}
              background={teaserReferenceBackground}
              backgroundHover={teaserReferenceBackgroundHover}
              buttonBackground={"linear-gradient(45deg, #672779, #D82EB4)"}
              state={{filter: 'cmec' }}
            >
              CoreMedia Engagement Cloud is the analytics and customer contact part of the CoreMedia Experience Platform.
              Find out what's new.
            </HomepageTeaser>
          </Col>
        </Row>

      </Style>
    </ContainerWrapper>
  )
}

export const Head = () =>
  <link rel="alternate" type="application/rss+xml"
        title="CoreMedia Experience Cloud - What's New RSS Feed"
        href="/whats-new-rss.xml"/>

export default WhatsNew
