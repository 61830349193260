/**
 * CoreMedia CI and some standard colours
 */
const colors = {
  tangyTurquoise: '#6fc3b8',
  paranoidPurple: '#672779',
  radicalRed: '#dd342b',
  yellingYellow: '#efdf0f',
  bettyBlue: '#006cae',
  gabbyGreen: '#2FAC66',
  lightBlue: '#0093df',
  lightPurple: '#9444b4',
  monteCarlo: '#6FC3B8',

  gray: '#efefef',
  darkGray: '#c5c5c5',
  white: '#fff',
  black: '#000',

  greenGradient: 'linear-gradient(-45deg,#2FAC66, #006CAE )',
  purpleGradient: 'linear-gradient(-45deg, #DD342B, #D82EB4)',
  magentaGradient: 'linear-gradient(-45deg, #672779, #D82EB4 )',
  whiteGradient: 'linear-gradient(45deg, #ffffff, #ffffff)',
  monteCarloGradient: 'linear-gradient(-45deg, #672779, #6FC3B8 )',
};

export default colors;

/**
 * Allowed background and text color combinations for layout rows
 */
export const rowColorCombinations = [
  { backgroundColor: colors.whiteGradient, color: colors.black },
  { backgroundColor: colors.greenGradient, color: colors.white },
  { backgroundColor: colors.whiteGradient, color: colors.black },
  { backgroundColor: colors.monteCarloGradient, color: colors.white },
];
