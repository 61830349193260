import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../lib/coremedia-colors';
import buttonNormal from './images/Pfeil.svg';
import buttonHover from './images/Punkt.svg';
import Link from "gatsby-link";

const Container = styled(Link)`
  margin: 24px 0;
  padding: 0.1875rem 0.9375rem 0.1875rem 0.9375rem;
  max-width: 280px;
  text-align: left;
  border-radius: 30px;
  font-family: 'DM Sans, Simplon Norm Medium', 'Lucida Sans', 'Lucida Sans Unicode',
  'Lucida Grande', Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  background-image: ${(props) => props.bg};
  color: ${(props) => props.color} !important;
  display: inline-block;
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;

  @media only screen and (max-width: 1200px) {
    margin-left: -50px;
  }

  @media only screen and (max-width: 992px) {
    margin-left: 0px;
  }

  &:after {
    content: "";
    background-image: url(${buttonNormal});
    background-size: 20px; /* Icon size */
    width: 20px; /* Icon size */
    height: 20px; /* Icon size */
    position: absolute;
    right: 6px; /* Adjust distance from the right side */
    top: 50%; /* Vertically center the icon */
    transform: translateY(-50%); /* Vertically center the icon */
  }

  &:hover:after {
    background-image: url(${buttonHover});
  }

  &:active,
  &:visited {
    color: ${(props) => props.color};  !important;
  }

  /* Ensure the color applies to all nested elements */
  & > * {
    color: ${(props) => props.color};!important;
  }
`;

const BigButton = ({
  cta,
  textColor = colors.white,
  buttonBackground = colors.tangyTurquoise,
  state
}) => {
  return (
    <Container to={cta.link} bg={buttonBackground} color={textColor} state={state}>
      {cta.label}
    </Container>
  );
};

BigButton.propTypes = {
  /** Children, e.g an <a> node */
  cta: PropTypes.object.isRequired,
  /** Optional text color, defaults to white */
  state: PropTypes.object,
  textColor: PropTypes.string,
  /** Optional background color, defaults to Tangy Turquois */
  buttonBackground: PropTypes.string,
};

export default BigButton;
